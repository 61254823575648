import { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import { AppContext } from "../context"
import Back from "../assets/images/Back.svg"
import Plus from "../assets/images/Plus.svg"
import Close from "../assets/images/Close.svg"
import examplePledges from "../examplePledges.json"


const Pledge = () => {
    const maxCharacters = 200;;
    const [charactersRemaining, setCharactersRemaining] = useState(maxCharacters)
    const [textContent, setTextContent] = useState("")
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [displayError, setDisplayError] = useState(false)
    const { appData, setAppData } = useContext(AppContext)
    const navigate = useNavigate()
    const filteredPledges = examplePledges.examples.filter(example => example.topic === appData.selectedTopic.topic)

    function handleSubmit() {
        const regex = /([{}|^~[\]`<>#%"'\\])/g
        if (regex.test(textContent)) {
            setDisplayError(true);
            return
        }
        navigate("/transition")
        setAppData({ ...appData, userPledge: { pledge: textContent, topic: appData.selectedTopic.topic } })
    }

    function calculateCharacters(e: any) {
        setCharactersRemaining(maxCharacters - e.target.value.length)
        setTextContent(e.target.value)
    }

    return (
        <motion.div
            className="min-h-screen h-full flex flex-col items-center justify-between bg-gradient-radial"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}

        >
            <div className="w-full h-full absolute left-0 top-0 backdrop-blur-sm bg-neutral-950 bg-opacity-50 transition-all duration-500 z-10" style={{ opacity: drawerOpen ? "1" : "0", pointerEvents: drawerOpen ? "auto" : "none" }}>
                <div className="w-[70%] h-full bg-gradient-radial transition-all duration-500" style={{ transform: drawerOpen ? "translateX(0)" : "translateX(-100%)" }}>
                    <div className="relative w-full h-[10%] flex items-center justify-center flex bg-red-500">
                        <h1 className="text-white text-lg TBL-SM:text-xl TBL-LG:text-2xl DSK-SM:text-3xl DSK-LG:text-5xl">Example Submissions</h1>
                        <img alt="Cross" src={Close} className="absolute right-[2em] h-1/2 w-fit z-10 cursor-pointer" onClick={() => setDrawerOpen(false)} />
                    </div>
                    <div className="w-full h-[90%] px-5 overflow-y-auto text-2xl">
                        {filteredPledges.map((examplePledges) => {
                            return (
                                <div className="w-full h-fit my-[1em] border-2 border-white" key={examplePledges.id}>
                                    <div className="relative w-full h-[15%] flex items-center justify-center pt-[.75em]
                                    text-xl
                                    MBL-SM:text-2xl
                                    MBL-LG:text-3xl
                                            
                                    TBL-SM:text-4xl
                                    TBL-LG:text-5xl
                                            
                                    DSK-SM:text-6xl
                                    DSK-LG:text-8xl">
                                        <div className="justify-center text-center items-center content-center mr-2 w-4 h-4 rounded-full TBL-SM:w-6 TBL-SM:h-6 DSK-SM:w-8 DSK-SM:h-8 TBL-LG:mr-4 DSK-LG:w-12 DSK-LG:h-12 DSK-LG:mr-6" style={{ backgroundColor: `${appData.selectedTopic.colour}` }} />
                                        <h1 className="text-white">{appData.selectedTopic.topic || "Select a topic"}</h1>
                                    </div>
                                    <div className="relative w-full text-center justify-center px-[1em] py-[.5em] flex
                                    text-base
                                    MBL-SM:text-xl
                                    MBL-LG:text-2xl
                                            
                                    TBL-SM:text-3xl
                                    TBL-LG:text-4xl
                                            
                                    DSK-SM:text-5xl
                                    DSK-LG:text-7xl">
                                        <p className="text-white">{examplePledges.text}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="relative w-full h-[15%] m-2 flex items-center justify-center text-2xl TBL-SM:text-4xl TBL-LG:text-5xl DSK-SM:text-6xl DSK-LG:text-7xl">
                <img className="absolute top-[50%] -translate-y-[50%] left-4 h-1/2" alt="Back button" src={Back} onClick={() => navigate("/topic")} />
                <div className="mr-2 w-4 h-4 rounded-full TBL-SM:w-6 TBL-SM:h-6 DSK-SM:w-8 DSK-SM:h-8 TBL-LG:mr-4 DSK-LG:w-12 DSK-LG:h-12 DSK-LG:mr-6" style={{ backgroundColor: `${appData.selectedTopic.colour}` }} />
                <h1 className="text-white">{appData.selectedTopic.topic || "Select a topic"}</h1>
            </div>
            <div className="flex h-full w-[80%]">
                <div className="h-full w-[65%] flex flex-col">
                    <div className="h-[10%] w-full">
                        <div className="flex w-full h-full items-end justify-between">
                            <h1 className="text-white text-md TBL-SM:text-2xl TBL-LG:text-3xl DSK-SM:text-4xl DSK-LG:text-5xl ">My action is to</h1>
                            <h1 className="text-white text-sm TBL-SM:text-lg TBL-LG:text-xl DSK-SM:text-2xl DSK-LG:text-3xl opacity-50">{charactersRemaining} characters left</h1>
                        </div>
                    </div>
                    <div className="flex flex-col w-full h-[20vh] max-h-[200px]">
                        <textarea onKeyUp={calculateCharacters} maxLength={maxCharacters} className="w-full h-full text-lg TBL-SM:text-2xl TBL-LG:text-3xl DSK-SM:text-4xl DSK-LG:text-5xl" />
                        {displayError ? <p className="text-red-500 text-sm TBL-SM:text-lg TBL-LG:text-xl DSK-SM:text-2xl DSK-LG:text-3xl">Invalid character (&#91;&#93;&#123;&#125;|^~`\&lt;&gt;#&%"') entered. Please remove the character and try again.</p> : null}
                    </div>
                </div>
                <div className="h-full w-[35%] flex flex-col">
                    <div className="h-[10%] w-full">
                    </div>
                    <div className="flex justify-center items-center w-full h-fit">
                        <button disabled={charactersRemaining === maxCharacters} className="text-white py-2 px-6 w-fit text-md text-2xl bg-white bg-opacity-30 backdrop-blur-sm border-[1px] disabled:opacity-50
                            MBL-LG:px-8 MBL-LG:py-4 MBL-LG:text-xl 
                            TBL-SM:text-2xl 
                            TBL-LG:px-14 TBL-LG:py-8 TBL-LG:text-3xl 
                            DSK-SM:text-4xl DSK-SM:px-20 DSK-SM:py-10 
                            DSK-LG:px-28 DSK-LG:py-12 DSK-LG:text-5xl " onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 m-2 DSK-SM:m-4 flex items-center justify-center TBL-LG:text-2xl DSK-SM:text-3xl DSK-LG:text-4xl" onClick={() => setDrawerOpen(true)}>
                <img className="h-full mr-4 w-4 TBL-SM:w-6 TBL-LG:w-8 DSK-SM:w-10 DSK-LG:w-12" alt="Plus button" src={Plus} />
                <h1 className="text-white">Need inspiration? <br />See others actions</h1>
            </div>
        </motion.div >
    )
}

export default Pledge