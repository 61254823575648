import { Fragment, useState, useContext } from "react"
import { isBrowser } from "react-device-detect"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../context"
import ShareQR from "../assets/qrs/ShareQR.png"
import ReportsQR from "../assets/qrs/ReportsQR.png"
import ReportsDrawer from "../components/ReportsDrawer"

const ExploreButtons = () => {
    const navigate = useNavigate()
    const { appData, setAppData } = useContext(AppContext)
    const [drawerOpen, setDrawerOpen] = useState(false)

    function handleRestart() {
        setAppData({
            ...appData, selectedTopic: {
                ...appData.topics[1]
            }
        })
        navigate('/topic')
    }

    if (isBrowser) {
        return (
            <Fragment>
                <div className="w-full flex flex-col" >
                    <div className="relative w-full  flex items-center justify-between mb-[1em]">
                        <h1 className="w-[70%] leading-auto">Find access to your topic reports here</h1>
                        <img className="w-[30%] ml-[2%] aspect-square" alt="QR" src={ReportsQR} />
                    </div>
                    <div className="relative w-full flex items-center justify-between">
                        <h1 className="w-[70%]">Share</h1>
                        <img className="w-[30%] ml-[1em] aspect-square" alt="QR" src={ShareQR} />
                    </div>
                </div >
                <div className="w-fit flex items-end justify-end">
                    <button onClick={() => handleRestart()} className={`w-full max-w-xs h-fit border-[1px] p-[1em]`} >Add another action</button>
                </div>
            </Fragment >
        )
    }

    return (
        <Fragment>
            <div className="absolute right-0 top-0 w-full h-full  flex justify-end backdrop-blur-sm bg-neutral-950 bg-opacity-50 transition-all duration-500 z-10 overflow-hidden" style={{ opacity: drawerOpen ? "1" : "0", pointerEvents: drawerOpen ? "auto" : "none" }}>
                <div className="w-[70%] h-full bg-gradient-radial transition-all duration-500 z-20" style={{ transform: drawerOpen ? "translateX(0)" : "translateX(100%)" }}>
                    <ReportsDrawer setDrawerOpen={setDrawerOpen} />
                </div>
            </div>
            <div className="w-full max-w-md flex flex-col">
                <button onClick={() => setDrawerOpen(true)} className={`w-full h-fit text-white border-[1px] mb-[1em] p-[1em]`} >Download your reports here</button >
                <button onClick={() => window.open("https://linktr.ee/McKinseyPledgeWall", "_blank")} className={`w-full h-fit text-white border-[1px] p-[1em]`} >Share</button>
            </div >
            <div className="w-full max-w-md">
                <button onClick={() => handleRestart()} className={`w-full h-fit text-white border-[1px] p-[1em]`} >Add another action</button>
            </div>
        </Fragment>


    )
}

export default ExploreButtons