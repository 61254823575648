import ReportsDrawer from "../components/ReportsDrawer"

const Reports = () => {

    return (
        <div>
            <ReportsDrawer fullPage />
        </div>
    )
}

export default Reports