import { useState, useEffect, useRef, useContext } from "react"
import { isBrowser, isSafari } from "react-device-detect"

import DataDot from "./DataDot"

import iOSSphereSparse from "../assets/videos/SF_Globe_Sparse.mov"
import iOSSphereMid from "../assets/videos/SF_Globe_Mid.mov"
import iOSSphereFull from "../assets/videos/SF_Globe_Full.mov"
import SphereSparse from "../assets/videos/SF_Globe_Sparse.webm"
import SphereMid from "../assets/videos/SF_Globe_Mid.webm"
import SphereFull from "../assets/videos/SF_Globe_Full.webm"


// Context
import { AppContext } from "../context"

const SphereVideo = ({ selectedIndex }: any) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const filterRef = useRef('hue-rotate(0deg) contrast(0.5) saturate(0) brightness(1.5)')
    const { appData, setAppData } = useContext(AppContext)
    const [topicPledges, setTopicPledges] = useState<Pledge[]>([])
    const [renderDots, setRenderDots] = useState(false);
    const MAX_PLEDGES: number = 100;
    let DRAW_DELAY: number = 30;

    useEffect(() => {
        if (videoRef.current) {
            if (isSafari) {
                if (topicPledges.length <= 10) {
                    videoRef.current.src = iOSSphereSparse
                } else if (topicPledges.length > 10 && topicPledges.length <= 20) {
                    videoRef.current.src = iOSSphereMid
                } else {
                    videoRef.current.src = iOSSphereFull
                }
            } else {
                if (topicPledges.length <= 10) {
                    videoRef.current.src = SphereSparse
                } else if (topicPledges.length > 10 && topicPledges.length <= 20) {
                    videoRef.current.src = SphereMid
                } else {
                    videoRef.current.src = SphereFull
                }
            }
        }
    }, [topicPledges])

    useEffect(() => {
        setRenderDots(false);
        const videoTimer = setTimeout(() => {
            if (videoRef.current) {
                try {
                    videoRef.current.style.opacity = "1"
                    videoRef.current.play()
                } catch (err: any) {
                    if (err.name === "AbortError") return;
                    console.log("Error ", err)
                }
            }
        }, 400)
        if (videoRef.current) {
            videoRef.current.style.opacity = "0";
        }
        const dotTimer = setTimeout(() => {
            setRenderDots(true);
        }, 1500)
        if (appData.selectedTopic?.topic === "All Topics") {
            const pledges = appData.pledges.slice(0, MAX_PLEDGES);
            setTopicPledges(pledges)
        } else {
            const pledges = appData.pledges.filter((pledge) => {
                return pledge.topic === appData.selectedTopic?.topic
            });
            setTopicPledges(pledges.slice(0, MAX_PLEDGES));
        }
        return () => {
            clearTimeout(dotTimer)
            clearTimeout(videoTimer)

        }
    }, [appData.selectedTopic])

    useEffect(() => {
        const dataDiv = document.getElementById("dataDiv")
        if (!dataDiv) return
        if (renderDots) {
            dataDiv.childNodes.forEach((node: any, idx: number) => {
                setTimeout(() => {
                    (node as HTMLDivElement).style.opacity = "1";
                }, DRAW_DELAY)
                if (idx % 2 === 0) {
                    setTimeout(() => {
                        (node as HTMLDivElement).classList.add("animate-ping2")
                    }, DRAW_DELAY + 500)
                }
                DRAW_DELAY += 20
            })
        }
    }, [renderDots])

    useEffect(() => {

    }, [appData.selectedTopic])

    useEffect(() => {
        if (filterRef.current) {
            // Handles All Topics, not present in AppData as All Topics added just for this page
            if (appData.topics[selectedIndex] === undefined) {
                // Must be the same as initialised value
                filterRef.current = 'hue-rotate(0deg) contrast(0.5) saturate(0) brightness(1.5)'
            }
            switch (appData.topics[selectedIndex]?.id - 1) {
                case 0:
                    filterRef.current = 'hue-rotate(170deg) contrast(0.75) saturate(1.5) brightness(1.25)'
                    break;
                case 1:
                    filterRef.current = 'hue-rotate(110deg) contrast(.5) saturate(1)'
                    break;
                case 2:
                    filterRef.current = 'hue-rotate(-30deg) contrast(1) saturate(0.5) brightness(1.5)'
                    break;
                case 3:
                    filterRef.current = 'hue-rotate(60deg) contrast(1) saturate(0.5) brightness(1.5)'
                    break;
                case 4:
                    filterRef.current = 'hue-rotate(75deg) contrast(1) saturate(0.1) brightness(1.5)'
                    break;
                case 5:
                    filterRef.current = 'hue-rotate(0deg) contrast(1) saturate(1.25)'
                    break;
                case 6:
                    filterRef.current = 'hue-rotate(80deg) contrast(1) saturate(0.8) brightness(0.8)'
                    break;
                case 7:
                    filterRef.current = 'hue-rotate(145deg) contrast(0.5) saturate(0.5) brightness(1.5)'
                    break;
                case 8:
                    filterRef.current = 'hue-rotate(260deg) contrast(.8) saturate(.7) brightness(1)'
                    break;
                case 9:
                    filterRef.current = 'hue-rotate(145deg) contrast(1) saturate(1.2) brightness(0.7)'
                    break;
            }
        }
    }, [selectedIndex])

    return (
        <>
            <div className='h-full w-full rounded-full'>
                <video
                    ref={videoRef}
                    className='h-full w-full'
                    muted
                    playsInline
                    style={{ filter: filterRef.current }}
                >
                    <source />
                </video>
            </div>
            <div
                id='dataDiv'
                className='absolute left-1/2 top-1/2 h-[90%] w-[90%] -translate-x-1/2 -translate-y-1/2 rounded-full overflow-hidden'
            >
                {renderDots &&
                    topicPledges.map((pledge: Pledge) => {
                        const MIN = 18
                        const topValue = Math.round(Math.random() * 62 + MIN)
                        const MAX = 100 - MIN
                        let leftValue
                        if (topValue > MIN && topValue < 30) {
                            leftValue = Math.round(Math.random() * 40 + 30)
                        }
                        if (topValue >= 30 && topValue < 42) {
                            leftValue = Math.round(Math.random() * 60 + 20)
                        }
                        if (topValue >= 42 && topValue < 54) {
                            leftValue = Math.round(Math.random() * 70 + 15)
                        }
                        if (topValue >= 54 && topValue < 66) {
                            leftValue = Math.round(Math.random() * 60 + 20)
                        }
                        if (topValue >= 66 && topValue < MAX) {
                            leftValue = Math.round(Math.random() * 40 + 30)
                        }
                        return (
                            <DataDot
                                key={pledge.id}
                                topValue={topValue}
                                leftValue={leftValue}
                                pledgeColour={pledge.topic_colour}
                            />
                        )
                    })}
            </div>
        </>
    )
}

export default SphereVideo
