const DataDot = ({ topValue, leftValue, scale, pledgeColour }: any) => {

    return (
        <>
            <div className="absolute w-[2.5%] aspect-square rounded-full transition-all duration-500 z-20" style={{ top: `${topValue}%`, left: `${leftValue}%`, opacity: 0, transform: `scale(${scale})`, backgroundColor: pledgeColour }} />
            <div className="absolute w-[2.5%] aspect-square rounded-full transition-all duration-500 z-20" style={{ top: `${topValue}%`, left: `${leftValue}%`, opacity: 0, transform: `scale(${scale})`, backgroundColor: pledgeColour }} />
        </>
    )
}

export default DataDot
