import axios from "axios"

async function getAppData(setAppData: any, appData: AppData) {
    let pledges: any[] = []
    let topics: any[] = []
    axios.get(`${process.env.REACT_APP_SERVER_URL}`).then((res) => {
        pledges = res.data.filter((pledge: Pledge) => pledge.status === "APPROVED")
    }).then(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/topics`).then((res) => {
            topics = res.data
        }).then(() => {
            setAppData({ ...appData, pledges: pledges, topics: topics })
        })
    })
}

async function getAllPledges(setPledgeData: any) {
    axios.get(`${process.env.REACT_APP_SERVER_URL}`).then((res) => {
        setPledgeData(res.data)
    })
}

async function patchPledge(id: number, status: string, setPledgeData: any) {
    const body = {
        id: id,
        status: status
    }
    axios.patch(`${process.env.REACT_APP_SERVER_URL}`, body).then((res) => {
        getAllPledges(setPledgeData)
    })
}

function postPledge(appData: AppData) {
    const body = {
        pledge: appData.userPledge.pledge,
        topic: appData.userPledge.topic
    }
    axios.post(`${process.env.REACT_APP_SERVER_URL}`,
        body
    )
}

export { getAppData, getAllPledges, patchPledge, postPledge }