import { Fragment, useState, useEffect, useContext } from "react"
import { AppContext } from "../context"

const VerticalCarousel = ({ selectedIndex, setSelectedIndex, topics, setTopics, explore }: any) => {
    const { appData, setAppData } = useContext(AppContext)
    const [hasScrolled, setHasScrolled] = useState(false);
    let touchStart: number;
    let touchEnd: number;

    if (explore) {
        topics = [...appData.topics, { id: appData.topics.length + 1, topic: "All Topics", colour: "#FFFFFF" }]
    } else {
        topics = [...appData.topics]
    }

    function goUp() {
        if (selectedIndex === topics.length - 1) {
            setSelectedIndex(0)
        } else {
            setSelectedIndex((prev: any) => prev + 1)
        }
    }

    function goDown() {
        if (selectedIndex === 0) {
            setSelectedIndex(topics.length - 1)
        } else {
            setSelectedIndex((prev: any) => prev - 1)
        }
    }

    // Calculate indexes of 2 positions above and below selected index
    // Format [Pos at -2, pos at -1, pos at +1, pos at +2]
    function calculatePositions() {
        if (selectedIndex === 0) {
            return [topics.length - 2, topics.length - 1, selectedIndex + 1, selectedIndex + 2]
        }
        if (selectedIndex === 1) {
            return [topics.length - 1, 0, selectedIndex + 1, selectedIndex + 2]
        }
        if (selectedIndex === topics.length - 1) {
            return [selectedIndex - 2, selectedIndex - 1, 0, 1]
        }
        if (selectedIndex === topics.length - 2) {
            return [selectedIndex - 2, selectedIndex - 1, selectedIndex + 1, 0]
        }
        return [selectedIndex - 2, selectedIndex - 1, selectedIndex + 1, selectedIndex + 2]
    }

    useEffect(() => {
        const newData = {
            ...appData,
            selectedTopic: topics[selectedIndex],
        }
        setAppData(newData)
    }, [selectedIndex])

    useEffect(() => {
        setSelectedIndex(0)
    }, [])

    function handleWheel(e: any) {
        if (!hasScrolled) {
            if (e.deltaY < 0) {
                goUp()
            } else {
                goDown()
            }
            setHasScrolled(true)
            setTimeout(() => {
                setHasScrolled(false)
            }, 100)
        }
    }

    function handleTouchStart(e: any) {
        if (e.targetTouches) {
            touchStart = e.targetTouches[0].clientY
        }
    }

    function handleTouchEnd(e: any) {
        if (e.targetTouches) {
            touchEnd = e.changedTouches[0].clientY
            if (Math.abs(touchStart - touchEnd) > 30) {
                if (touchStart < touchEnd) {
                    goUp()
                } else {
                    goDown()
                }
            }
        }
    }

    return (
        // <div className="w-full h-full flex items-center justify-center overflow-hidden">
        <div className="relative w-full h-full flex items-center justify-center" onWheel={handleWheel} onTouchEnd={handleTouchEnd} onTouchStart={handleTouchStart}>
            <div className="absolute left-0 w-[4%] aspect-square flex">
                <div className="w-full h-full rounded-full" style={{ backgroundColor: `${topics[selectedIndex]?.colour}` }} />
            </div>
            <div className="absolute border-t-[.05em] w-[75%] translate-y-[1.5em]" />
            <div className="absolute border-t-[.05em] w-[75%] -translate-y-[1.5em]" />
            <div className="h-full w-[96%] flex items-center justify-center">
                {topics?.map((topic: Topic, idx: number) => {
                    const positions = calculatePositions()
                    return (
                        <Fragment key={idx}>
                            <h1 className={`absolute flex items-center justify-center w-full text-center text-white transition-all duration-100 ease-in-out opacity-0 select-none
                            ${selectedIndex === idx && `text-[1.325em] !opacity-100`}
                            ${idx === positions[0] && `translate-y-[6.5em] text-[.75em] !opacity-30 cursor-pointer`} 
                            ${idx === positions[1] && `translate-y-[3em] text-[1em] !opacity-80 cursor-pointer`} 
                            ${idx === positions[2] && `-translate-y-[3em] text-[1em] !opacity-80 cursor-pointer`} 
                            ${idx === positions[3] && `-translate-y-[6.5em] text-[.75em] !opacity-30 cursor-pointer`}
                        `}
                                onClick={() => {
                                    if (idx === positions[1] || idx === positions[0]) {
                                        goDown()
                                    } else if (idx === positions[2] || idx === positions[3]) {
                                        goUp()
                                    }
                                }}>{topic.topic}</h1>
                        </Fragment>
                    )
                })}

            </div>
        </div>
        // </div>
    )
}

export default VerticalCarousel