import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { useState, useEffect, useRef } from "react"
import { AnimatePresence } from "framer-motion"
import { useIdleTimer } from "react-idle-timer";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Start from "./pages/Start";
import Topic from "./pages/Topic";
import Pledge from "./pages/Pledge";
import Transition from "./pages/Transition";
import Explore from "./pages/Explore";
import Moderator from "./pages/Moderator";
import Reports from "./pages/Reports";
import Rotate from "./assets/images/Rotate.svg"
import './App.css';
import { AppContext } from "./context";
import { getAppData } from "./helpers/api";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLandscape, setIsLandscape] = useState(true);
  const [isIdle, setIsIdle] = useState(false);
  const [SVGSize, setSVGSize] = useState(100);
  const [appData, setAppData] = useState<AppData>({
    selectedTopic: {
      id: 1,
      topic: "Innovation & Technology",
      colour: "#F3AB3C"
    },
    userPledge: {
      pledge: "",
      topic: ""
    },
    pledges: [],
    topics: []
  })
  const contextValue = { appData, setAppData }

  useEffect(() => {
    getAppData(setAppData, appData)
    handleWindowChange()
  }, [])

  window.addEventListener('resize', () => {
    handleWindowChange()
  }) 

  function handleWindowChange() {
    if (window.innerWidth / window.innerHeight > 1) {
      setIsLandscape(true)
    } else {
      setIsLandscape(false);
    }
    if (window.innerWidth <= 768) {
      setSVGSize(100)
    } else
      if (window.innerWidth > 768 && window.innerWidth <= 1366) {
        setSVGSize(160)
      } else
        if (window.innerWidth > 1366 && window.innerWidth <= 1920) {
          setSVGSize(150)
        }
    if (window.innerWidth > 1920 && window.innerWidth <= 2560) {
      setSVGSize(280)
    }
  }

  function restartApp() {
    navigate("/")
    window.location.reload()
  }

  function onIdle() {
    if (location.pathname === "/" || location.pathname === "/moderator") {
      return
    }
    setIsIdle(true)
  }

  function onAction() {
    setIsIdle(false)
  }

  useIdleTimer({
    timeout: 1000 * 120,
    onIdle,
    onAction
  })

  const renderTime = ({ remainingTime }: any) => {
    return <div>{remainingTime}</div>
  }

  return (
    <AnimatePresence mode="wait">
      <AppContext.Provider value={contextValue}>
        {!isLandscape && location.pathname !== "/reports" && <div className="absolute top-0 h-[100dvh] w-[100dvw] backdrop-blur-lg bg-black bg-gradient-radial flex items-center justify-center z-50"> <img className="max-w-[40%]" src={Rotate} alt="Rotate device" /> </div>}
        {isIdle && <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center backdrop-blur-lg z-20">
          <div className="flex w-full items-center justify-center text-xl TBL-SM:text-3xl TBL-LG:text-4xl DSK-SM:text-5xl DSK-LG:text-6xl">
            <div className="text-white w-fit ml-4 flex flex-col items-start justify-center">
              <h1 className="mb-[.2em]">Are you still there?</h1>
              <h1 className="text-xs TBL-SM:text-xl TBL-LG:text-2xl DSK-SM:text-3xl DSK-LG:text-4xl">Tap to continue</h1>
            </div>
            <div className="text-white h-full w-fit ml-4 flex items-center justify-center ml-[1.25em]">
              <CountdownCircleTimer isPlaying duration={10} colors={["#FFFFFF", "#FFFFFF"]} size={SVGSize} trailStrokeWidth={5} strokeWidth={5}
                colorsTime={[10, 5]} trailColor="rgba(0,0,0,0.5)" initialRemainingTime={10} onComplete={restartApp}
              >{renderTime}</CountdownCircleTimer>
            </div>
          </div>
        </div>}
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Start />} />
          <Route path="/topic" element={<Topic />} />
          <Route path="/pledge" element={<Pledge />} />
          <Route path="/transition" element={<Transition />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/moderator" element={<Moderator />} />
          <Route path="/reports" element={<Reports />} />
        </Routes>
      </AppContext.Provider>
    </AnimatePresence>
  );
}

export default App;
