import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import axios from "axios"

// Assets
import Adaptation from "../assets/images/ArtboardAdaptation.png"
import BuiltEnvironment from "../assets/images/ArtboardBuiltEnvironments.png"
import CarbonManagement from "../assets/images/ArtboardCarbonMngmng.png"
import ConsumerGoods from "../assets/images/ArtboardConsumerGoods.png"
import Decarbonization from "../assets/images/ArtboardDeCarb.png"
import Energy from "../assets/images/ArtboardEnergy.png"
import Finance from "../assets/images/ArtboardFinance.png"
import Innovation from "../assets/images/ArtboardInno&Tech.png"
import Nature from "../assets/images/ArtboardNature.png"
import NavTrans from "../assets/images/ArtboardNavTrans.png"
import BG from "../assets/images/BG.svg"
import Logo from "../assets/images/Logo.svg"
import PledgeFooter from "../components/PledgeFooter"

const Start = () => {
    const navigate = useNavigate()
    const backgroundRef = useRef<HTMLImageElement | null>(null)

    async function handleFlash() {
        axios.get(`${process.env.REACT_APP_SERVER_URL}`).then((res) => {
            const date = Date.now()
            if (res.data[0].date) {
                const timestamp = new Date(res.data[0].date).getTime()
                if (date - timestamp < 3500) {
                    if (backgroundRef.current) {
                        switch (res.data[0].topic) {
                            case "Innovation & Technology":
                                backgroundRef.current.src = Innovation
                                break
                            case "Adaptation":
                                backgroundRef.current.src = Adaptation
                                break
                            case "Finance":
                                backgroundRef.current.src = Finance
                                break
                            case "Energy":
                                backgroundRef.current.src = Energy
                                break
                            case "Navigating The Transition":
                                backgroundRef.current.src = NavTrans
                                break
                            case "Decarbonizing Industry":
                                backgroundRef.current.src = Decarbonization
                                break
                            case "Carbon Management":
                                backgroundRef.current.src = CarbonManagement
                                break
                            case "Nature":
                                backgroundRef.current.src = Nature
                                break
                            case "Consumer Goods and Materials":
                                backgroundRef.current.src = ConsumerGoods
                                break
                            case "Built Environment":
                                backgroundRef.current.src = BuiltEnvironment
                                break
                        }
                        backgroundRef.current.style.opacity = "1"
                        setTimeout(() => {
                            if (backgroundRef.current) {
                                backgroundRef.current.style.opacity = "0"
                            }
                        }, 600)
                    }
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            handleFlash()
        }, 3000)
        return () => clearInterval(interval)
    }, [])

    return (
        <motion.div
            className="min-h-screen h-full flex flex-col items-center justify-between bg-gradient-radial overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <img className="absolute h-full object-cover opacity-50 z-[0]" alt="Background" src={BG} />
            <img className="absolute h-full object-cover opacity-0 transition-all duration-[800ms] z-[1]" alt="Background" ref={backgroundRef} />
            <div className="h-[12.5%] w-full flex flex-col items-start justify-center bg-gradient-to-r from-[#051c2a] to-[#1030ab] z-[2]" onClick={() => navigate("/")}>
                <img src={Logo} alt="Logo" className="max-h-full h-full p-2 ml-[5%] TBL-SM:py-4 DSK-LG:py-8" />
            </div>
            <div className="h-[75%] w-full flex items-center justify-between z-[2]">
                <div className="h-fit w-[50%] pr-[7.5%] ml-[7.5%] flex flex-col justify-center items-start MBL-LG:text-xl TBL-SM:text-3xl TBL-LG:text-4xl DSK-SM:text-5xl DSK-LG:text-6xl">
                    <h1 className="text-white text-md mb-[2%]">To reach net zero, we need to accelerate the rate of change. Together, we can make progress that can be measured in months, not decades.</h1>
                    <h1 className="text-white text-md">We need to start now. What can you do tomorrow to take bold climate action?</h1>
                </div>
                <div className="h-full w-[50%] px-8 flex flex-col justify-center items-center">
                    <h1 className="text-white text-3xl text-center mb-4 TBL-SM:mb-12 DSK-SM:mb-16 DSK-LG:mb-32 MBL-LG:text-4xl TBL-SM:text-5xl TBL-LG:text-6xl DSK-SM:text-7xl DSK-LG:text-8xl">What is your <br /> climate action?</h1>
                    <button onClick={() => navigate("/topic")} className="text-white w-2/3 max-w-lg text-2xl bg-white bg-opacity-30 backdrop-blur-sm p-4 border-[1px] MBL-LG:text-3xl TBL-SM:text-4xl TBL-SM:p-8 TBL-LG:text-5xl DSK-SM:text-6xl DSK-LG:p-16 DSK-LG:text-7xl">Start</button>
                </div>
            </div>
            <div className="h-[12.5%] w-full bg-gradient-to-r from-[#051c2a] to-[#1030ab] z-[2]">
                <PledgeFooter />
            </div>
        </motion.div>
    )
}

export default Start