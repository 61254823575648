import { useEffect, useState, useContext, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import { AppContext } from "../context"
import { postPledge } from "../helpers/api"
import RippleVideo from "../components/RippleVideo"
import Back from "../assets/images/Back.svg"
import Tap from "../assets/images/Tap.png"

const Transition = () => {
    const navigate = useNavigate()
    const { appData, setAppData } = useContext(AppContext)
    const [confirmed, setConfirmed] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null);
    const secondButtonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (confirmed) {
            setTimeout(() => {
                if (secondButtonRef.current) {
                    secondButtonRef.current.style.transform = "translateY(60vh)"
                }
            }, 50)
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.play()
                    videoRef.current.onended = () => {
                        navigate("/explore")
                    }
                }
            }, 1500)
        }
    }, [confirmed])

    function handleSubmit() {
        postPledge(appData)
        setConfirmed(true);
    }

    return (
        <motion.div
            className="h-full flex flex-col items-center justify-between bg-gradient-radial overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: "easeIn" }}
        >
            <div className="w-full h-full flex flex-col items-center justify-center overflow-hidden">
                <h1 className="text-5xl mb-[.5em] transition-all duration-500 TBL-SM:text-6xl TBL-LG:text-7xl DSK-SM:text-8xl DSK-LG:text-9xl" style={{ color: appData.selectedTopic.colour, opacity: `${confirmed && 0}` }} >{appData.selectedTopic.topic}</h1>
                <h1 className="text-white text-xl transition-all duration-500 mb-[.3em] TBL-SM:text-2xl TBL-LG:text-4xl DSK-SM:text-4xl DSK-LG:text-6xl" style={{ opacity: `${confirmed && 0}` }}>My climate action is to</h1>
                <h1 className="text-white text-xl transition-all duration-500 TBL-SM:text-2xl TBL-LG:text-3xl DSK-SM:text-4xl DSK-LG:text-6xl" style={{ opacity: `${confirmed && 0}` }}>{appData.userPledge.pledge}</h1>
                {confirmed ? <div ref={secondButtonRef} className={`w-16 h-16 flex items-center justify-center rounded-full mt-8 transition-all duration-1000 ease-in-out TBL-SM:w-24 TBL-SM:h-24 TBL-LG:w-32 TBL-LG:h-32 TBL-LG:mt-16 DSK-SM:w-42 DSK-SM:h-42 DSK-SM:w-48 DSK-SM:h-48`} style={{ backgroundColor: `${appData.selectedTopic.colour}` }} onClick={() => handleSubmit()}>
                </div> :
                    <div className={`w-16 h-16 flex items-center justify-center rounded-full mt-8 transition-all duration-1000 ease-in-out animate-pulsate TBL-SM:w-24 TBL-SM:h-24 TBL-LG:w-32 TBL-LG:h-32 TBL-LG:mt-16 DSK-SM:w-42 DSK-SM:h-42 DSK-SM:w-48 DSK-SM:h-48`} style={{ backgroundColor: `${appData.selectedTopic.colour}` }} onClick={() => handleSubmit()}>
                        <img className="p-2 w-2/3" src={Tap} alt="Tap icon" />
                    </div>}
                <div className="absolute h-full w-full pointer-events-none">
                    <RippleVideo confirmed={confirmed} />
                </div>
            </div>
            <div className="absolute h-[6%] max-h-xs TBL-LG:m-4 left-4 bottom-4 transition-all duration-500" style={{ opacity: `${confirmed ? 0 : 1}` }}>
                <img className="h-full" alt="Back button" src={Back} onClick={() => { if (!confirmed) { navigate("/pledge") } }} />
            </div>
        </motion.div>
    )
}

export default Transition