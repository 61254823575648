import Close from "../assets/images/Close.svg"
import DownloadReport from "../assets/images/DownloadReport.png"
import Reports from "../reports.json"

const ReportsDrawer = ({ fullPage, setDrawerOpen }: any) => {

    return (<>
        <div className="h-full bg-gradient-radial bg-[#0046ff] overflow-y-scroll">
            {!fullPage && <div className="relative w-full h-[10%] flex items-center justify-center flex bg-[#0046ff]">
                <img alt="Cross" src={Close} className="absolute aspect-square top-0 left-[1em] h-[50%] w-fit translate-y-1/2 translate-x-1/2 w-fit z-10 cursor-pointer" onClick={() => setDrawerOpen(false)} />
                <h1 className="text-white text-lg TBL-SM:text-xl TBL-LG:text-2xl DSK-SM:text-3xl DSK-LG:text-5xl">Download Reports</h1>
            </div>}
            <div className="w-full h-[90%] px-5 min-height-screen">
                {Reports.reports.map((reports) => {
                    return (
                        <div key={reports.id}>
                            <div className="pt-5 flex justify-center text-center items-center align-middle
                                            text-2xl]
                                            MBL-SM:text-3xl
                                            MBL-LG:text-4xl
                                            
                                            TBL-SM:text-5xl
                                            TBL-LG:text-6xl
                                            
                                            DSK-SM:text-6xl
                                            DSK-LG:text-7xl"
                                style={{ color: `${reports.hex}` }}>
                                {reports.heading}
                            </div>
                            <div className="w-full h-2/3 my-4 border-2 border-white">
                                <a className="grid grid-cols-[90%_10%] py-3 text-xl"
                                    href={reports.link} target="_blank">
                                    <div className="columns-1 border-r pr-3" >

                                        <p className="text-white pl-5 py-2
                                            text-base
                                            MBL-SM:text-xl
                                            MBL-LG:text-2xl
                                            
                                            TBL-SM:text-2xl
                                            TBL-MD:text-3xl
                                            TBL-LG:text-4xl
                                            
                                            DSK-SM:text-4xl
                                            DSK-MD:text-5xl
                                            DSK-LG:text-6xl">
                                            {reports.title}
                                        </p>
                                    </div>
                                    
                                    <div className="columns-1 justify-self-center flex items-center">
                                        <img alt="Download Report" src={DownloadReport} className="
                                        w-4
                                        MBL-SM:w-5
                                        MBL-LG:w-7
                                                                                    
                                        TBL-SM:w-7
                                        TBL-MD:w-7
                                        TBL-LG:w-7
                                                                                    
                                        DSK-SM:w-7
                                        DSK-MD:w-7
                                        DSK-LG:w-7" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </>)
}

export default ReportsDrawer