import { useState, useEffect, useContext } from "react"
import { motion } from "framer-motion"
import { AppContext } from "../context"
import VerticalCarousel from "../components/VerticalCarousel"
import PledgeFooter from "../components/PledgeFooter"
import ExploreButtons from "../components/ExploreButtons"

import SphereVideo from "../components/SphereVideo"

const Explore = () => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [topics, setTopics] = useState<Topic[]>([])
    const { appData, setAppData } = useContext(AppContext)

    useEffect(() => {
        setSelectedIndex(10)
    }, [])

    return (
        <motion.div
            className="min-h-screen h-full flex flex-col items-center justify-between bg-gradient-radial"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="w-full h-full">
                <div className="w-full h-[90%] flex p-[2.5%]">
                    <div className="absolute top-0 left-0 m-[2.5%]">
                        <h1 className="text-lg text-white MBL-LG:text-2xl TBL-SM:text-3xl TBL-LG:text-4xl DSK-SM:text-5xl DSK-LG-text-6xl">Explore Climate Actions</h1>
                        <h1 className="text-xs text-white MBL-LG:text-md TBL-SM:text-xl TBL-LG:text-2xl DSK-SM:text-3xl DSK-LG-text-4xl">Use the menu to select a topic</h1>
                    </div>
                    <div className="flex h-full w-full items-center justify-start">
                        <div className="w-[40%] h-[90%] text-xs flex items-center justify-center ml-[3.5%] mt-[5%] MBL-LG:text-[.9rem] TBL-SM:text-2xl TBL-LG:text-3xl DSK-LG:text-5xl">
                            <VerticalCarousel currentTopic={appData.selectedTopic} topics={topics} setTopics={setTopics} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} explore={true} />
                        </div>
                        <div className="w-[50%] relative flex items-center justify-center" >
                            <SphereVideo selectedIndex={selectedIndex} />
                        </div>
                        <div className="w-[25%] h-full py-[1%] pr-[1%] flex flex-col items-end justify-between text-xs text-white text-center TBL-SM:text-md TBL-LG:text-lg DSK-SM:text-2xl DSK-LG:text-3xl">
                            <ExploreButtons />
                        </div>
                    </div>
                </div>
                <div className="relative w-full h-[10%]">
                    <PledgeFooter topic={appData.selectedTopic} />
                </div>
            </div>
        </motion.div>
    )
}

export default Explore