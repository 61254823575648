import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import VerticalCarousel from "../components/VerticalCarousel"
import Back from "../assets/images/Back.svg"

const Topic = () => {
    const navigate = useNavigate()
    const [selectedIndex, setSelectedIndex] = useState<number>(1);
    const [topics, setTopics] = useState<Topic[]>([])

    return (
        <motion.div
            className="min-h-screen h-full min-w-screen flex flex-col items-center justify-between bg-gradient-radial overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="relative w-full h-[15%] m-2 flex items-center justify-center">
                <img className="absolute top-[50%] -translate-y-[50%] left-4 h-1/2 z-[10]" alt="Back button" src={Back} onClick={() => navigate("/")}/>
                <h1 className="text-white text-2xl TBL-SM:text-4xl DSK-SM:text-6xl DSK-LG:text-7xl">Choose a topic where you will take action</h1>
            </div>
            <div className="h-full w-full flex justify-around">
                <div className="w-[60%] px-[5%] flex items-center justify-center text-xs MBL-LG:text-base TBL-SM:text-2xl TBL-LG:text-3xl DSK-SM:text-4xl DSK-LG:text-5xl">
                    <VerticalCarousel selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} topics={topics} setTopics={setTopics} />
                </div>
                <div className="w-[50%] flex items-center justify-center">
                    <button onClick={() => navigate("/pledge")} className="text-white w-2/3 max-w-lg text-2xl bg-white bg-opacity-30 backdrop-blur-sm p-4 border-[1px] MBL-LG:text-3xl TBL-SM:text-4xl TBL-SM:p-8 TBL-LG:text-5xl DSK-SM:text-6xl DSK-LG:p-16 DSK-LG:text-7xl">Next</button>
                </div>
            </div>
        </motion.div>
    )
}

export default Topic