// @ts-nocheck
import { useState, useEffect, Fragment } from "react"
import { getAllPledges } from "../helpers/api"
import PledgeItem from "../components/PledgeItem"
import PinInput from "react-pin-input"

const Moderator = () => {
    const [orderedData, setOrderedData] = useState<Pledge[]>([])
    const [pledgeData, setPledgeData] = useState<Pledge[]>([])
    const [pinEntered, setPinEntered] = useState(false)

    useEffect(() => {
        getAllPledges(setPledgeData)
    }, [])

    useEffect(() => {
        if (pledgeData) {
            let totalArr = []
            totalArr = pledgeData.filter((pledge) => {
                return pledge.status === "UNMODERATED"
            }, [])
            pledgeData.filter((pledge) => {
                if (pledge.status !== "UNMODERATED") {
                    totalArr.push(pledge)
                }
            }, [])
            // approvedArr = pledgeData.filter((pledge) => {
            //     return pledge.status === "APPROVED"
            // }, [])
            // deniedArr = pledgeData.filter((pledge) => {
            //     return pledge.status === "DENIED"
            // }, [])
            // deniedArr.map((pledge) => {
            //     totalArr.push(pledge)
            // })
            // approvedArr.map((pledge) => {
            //     totalArr.push(pledge)
            // })
            setOrderedData(totalArr);
        }
    }, [pledgeData])

    function onComplete(value) {
        if (value === "1926") {
            setPinEntered(true)
        }
    }

    return (
        <div className="h-full w-full overflow-y-auto overflow-x-hidden bg-radial-gradient p-4">
            {pinEntered ?
                <table className="h-full w-full table-collapse overflow-y-scroll border-slate-500 border-collapse text-xs TBL-SM:text-lg DSK-SM:text-2xl DSK-LG:text-4xl">
                    <thead>
                        <tr className="text-white border-b-2 content-center box-border">
                            <td className="border-r-2 w-min p-2">ID</td>
                            <td className="border-r-2 w-min p-2">Action</td>
                            <td className="border-r-2 p-2">Topic</td>
                            <td className="border-r-2 p-2">Date</td>
                            <td className="border-r-2 p-2">Status</td>
                            <td className="p-2">Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {orderedData?.map((pledge) => {
                            return (
                                <Fragment key={pledge.id}>
                                    <PledgeItem setPledgeData={setPledgeData} pledge={pledge} />
                                </Fragment>
                            )
                        })}
                    </tbody>
                </table>
                :
                <div className="h-full w-full flex flex-col items-center justify-center">
                    <h1 className="text-white text-7xl mb-8">Enter Pin</h1>
                    <PinInput
                        length={4}
                        secret={false}
                        type="numeric"
                        onComplete={onComplete}
                        style={{ color: "white", height: "30vh", width: "30vw", display: "flex", justifyContent: "center", alignItems: "center" }}
                        inputStyle={{ color: "white", borderColor: "white", width: "100%", height: "100%", aspectRatio: "1/1", fontSize: "5rem" }}
                    />
                </div>
            }
        </div>
    )
}

export default Moderator 