import { patchPledge } from "../helpers/api"

const PledgeItem = ({ pledge, setPledgeData }: any) => {
    return (
        <tr className="text-white">
            <td className="p-2">{pledge.id}</td>
            <td className="p-2">{pledge.pledge}</td>
            <td className="p-2">{pledge.topic}</td>
            <td className="p-2">{pledge.date}</td>
            <td className="p-2">{pledge.status}</td>
            <td className="p-2 flex items-center justify-center"><span onClick={() => patchPledge(pledge.id, "APPROVED", setPledgeData)} className="uppercase bg-green-600 rounded py-[2px] px-[6px] cursor-pointer mx-[2px]">A</span> <span onClick={() => patchPledge(pledge.id, "DENIED", setPledgeData)} className="uppercase bg-red-600 rounded mx-[2px] py-[2px] px-[6px] cursor-pointer">D</span></td>
        </tr>

    )
}

export default PledgeItem;