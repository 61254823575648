import { createContext, useContext } from "react";

export const AppContext = createContext<AppContext>({
    appData: {
        selectedTopic: {
            id: 0,
            topic: "",
            colour: ""
        },
        userPledge: {
            pledge: "",
            topic: ""
        },
        pledges: [],
        topics: []
    },
    setAppData: () => { }
});