import { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../context";

interface IPledgeFooter {
    topic?: Topic
}

const PledgeFooter = ({ topic }: IPledgeFooter) => {
    const { appData, setAppData } = useContext(AppContext)
    const [recentPledges, setRecentPledges] = useState<any>([])
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const innerScrollerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        let arr: Pledge[]
        if (!topic || topic.topic === "All Topics") {
            const recentPledges = appData.pledges.slice();
            arr = [...recentPledges, ...recentPledges]
            setRecentPledges(arr)
        } else {
            const recentPledges = appData.pledges.filter((pledge) => {
                return pledge.topic === appData.selectedTopic.topic
            });
            arr = [...recentPledges, ...recentPledges];
            setRecentPledges(arr);
        }
    }, [appData])

    useEffect(() => {
        if (innerScrollerRef.current) {
            if (innerScrollerRef.current.childNodes.length) {
                const duration = innerScrollerRef.current.clientWidth * 7.5
                innerScrollerRef.current.animate([
                    { transform: "translateX(0)" },
                    { transform: 'translateX(-50%)' }
                ], {
                    duration: duration,
                    iterations: Infinity
                })
            }
        }
    }, [recentPledges])

    return (
        <div className="w-full h-full flex border-t-2 border-white">
            <div className="w-[20%] h-full flex items-center justify-center border-r-2 border-white">
                <h1 className="text-white text-sm MBL-LG:text-lg TBL-SM:text-2xl TBL-LG:text-3xl DSK-SM:text-4xl DSK-LG:text-5xl">Actions Given</h1>
            </div>
            <div id="scroller" className="w-full h-full overflow-hidden ">
                <div id="inner__scroller" ref={innerScrollerRef} className="h-full w-max flex gap-4 px-2">
                    {recentPledges.map((pledge: any, idx: any) => {
                        return (
                            <div key={idx} className="min-w-fit h-full flex items-center justify-center overflow-hidden MBL-LG:text-lg TBL-SM:text-2xl TBL-LG:text-3xl DSK-SM:text-4xl DSK-SM:mr-4 DSK-LG:text-5xl">
                                <div className={`w-[10px] h-[10px] rounded-full mr-[5px] MBL-LG:w-[15px] MBL-LG:h-[15px] TBL-SM:h-[20px] TBL-SM:w-[20px] TBL-SM:mr-[10px] TBL-LG:h-[25px] TBL-LG:w-[25px]  DSK-SM:h-[30px] DSK-SM:w-[30px]`} style={{ backgroundColor: `${pledge.topic_colour}` }} />
                                <h1 className="text-white">{pledge.pledge}</h1>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default PledgeFooter