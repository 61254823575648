import { useState, useEffect, useRef, useContext } from "react"
import { isMacOs, isIOS, isSafari, isChrome } from "react-device-detect"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../context"

// iOS Movs
import Adaptation from "../assets/videos/Adaptation.mov"
import BuiltEnvironment from "../assets/videos/BuiltEnvironment.mov"
import CarbonManagement from "../assets/videos/CarbonManagement.mov"
import ConsumerGoods from "../assets/videos/ConsumerGoods.mov"
import DecarbonizingIndustry from "../assets/videos/DecarbonizingIndustry.mov"
import Energy from "../assets/videos/Energy.mov"
import Finance from "../assets/videos/Finance.mov"
import InnovationTech from "../assets/videos/InnovationTech.mov"
import Nature from "../assets/videos/Nature.mov"
import NavTrans from "../assets/videos/Navtrans.mov"

// Webms
import AdaptationWebm from "../assets/videos/Adaptation.webm"
import BuiltEnvironmentWebm from "../assets/videos/BuiltEnvironment.webm"
import CarbonManagementWebm from "../assets/videos/CarbonManagement.webm"
import ConsumerGoodsWebm from "../assets/videos/ConsumerGoods.webm"
import DecarbonizingIndustryWebm from "../assets/videos/DecarbonizingIndustry.webm"
import EnergyWebm from "../assets/videos/Energy.webm"
import FinanceWebm from "../assets/videos/Finance.webm"
import InnovationTechWebm from "../assets/videos/InnovationTech.webm"
import NatureWebm from "../assets/videos/Nature.webm"
import NavTransWebm from "../assets/videos/Navtrans.webm"

const RippleVideo = ({ confirmed }: any) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const [source, setSource] = useState<any>()
    const navigate = useNavigate()
    const { appData, setAppData } = useContext(AppContext)

    useEffect(() => {
        if (confirmed) {
            if (videoRef.current) {
                switch (appData.selectedTopic.topic) {
                    case "Innovation & Technology":
                        if (isSafari || isIOS) {
                            setSource(InnovationTech)
                        } else {
                            setSource(InnovationTechWebm)
                        }
                        break
                    case "Adaptation":
                        if (isSafari || isIOS) {
                            setSource(Adaptation)
                        } else {
                            setSource(AdaptationWebm)
                        }
                        break
                    case "Finance":
                        if (isSafari || isIOS) {
                            setSource(Finance)
                        } else {
                            setSource(FinanceWebm)
                        }
                        break
                    case "Energy":
                        if (isSafari || isIOS) {
                            setSource(Energy)
                        } else {
                            setSource(EnergyWebm)
                        }
                        break
                    case "Navigating The Transition":
                        if (isSafari || isIOS) {
                            setSource(NavTrans)
                        } else {
                            setSource(NavTransWebm)
                        }
                        break
                    case "Decarbonizing Industry":
                        if (isSafari || isIOS) {
                            setSource(DecarbonizingIndustry)
                        } else {
                            setSource(DecarbonizingIndustryWebm)
                        }
                        break
                    case "Carbon Management":
                        if (isSafari || isIOS) {
                            setSource(CarbonManagement)
                        } else {
                            setSource(CarbonManagementWebm)
                        }
                        break
                    case "Nature":
                        if (isSafari || isIOS) {
                            setSource(Nature)
                        } else {
                            setSource(NatureWebm)
                        }
                        break
                    case "Consumer Goods and Materials":
                        if (isSafari || isIOS) {
                            setSource(ConsumerGoods)
                        } else {
                            setSource(ConsumerGoodsWebm)
                        }
                        break
                    case "Built Environment":
                        if (isSafari || isIOS) {
                            setSource(BuiltEnvironment)
                        } else {
                            setSource(BuiltEnvironmentWebm)
                        }
                        break
                }
            }
        }
    }, [confirmed])

    useEffect(() => {
        if (videoRef.current && source) {
            videoRef.current.onended = () => {
                navigate("/explore")
            }
            videoRef.current.load()
            videoRef.current.play()
        }
    }, [source])


    return <>
        <video ref={videoRef} className="h-full w-full object-cover" muted playsInline>
            <source src={source} />
        </video>
    </>


}
export default RippleVideo